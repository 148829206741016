import React, { FC } from "react";
import clsx from 'clsx'
import { Form } from "react-bootstrap";
import { OptionModel } from "../Models/OptionModel";
import _ from "lodash";

interface FormGroupSelectMoodProps {
    label: string;
    controlId: string;
    className?: string;
    name: string;
    onChange: (a: any, b: any) => void;
    onBlur?: (a: any, b: boolean) => void;
    value: any;
    isInvalidError?: string | undefined;
    touched?: boolean | undefined;
    options?: OptionModel[];
    required?: boolean;
    disabled?: boolean;
}

const FormGroupSelectMood: React.FC<FormGroupSelectMoodProps> = (props) => {
    let { label, controlId, className, value, name, isInvalidError, touched, required = true, disabled = false, onChange, onBlur, options, children } = props;

    const handleChange = (e, value) => {
        e.preventDefault();
        if(!disabled)
            onChange(name, value);
    };

    const checkIsActive = (id) => {
        if(value < 0){
            return "";
        }else{
            return (id != value) ? "deactivate" : "";
        }
    };

    return (
        <>
            <Form.Group className={className} controlId={controlId}>
                <div className={clsx('ic-widget-small__form-list__row', (touched && !!isInvalidError) ? "is-invalid" : "")}>             
                    <Form.Label>{label} {required ? <span className="text-danger">*</span> : <></>}</Form.Label>
                    <ul className='ic-widget-small__form-list__row__mood'>
                        <li className='ic-widget-small__form-list__row__mood__item-m'><button onClick={(e) => handleChange(e, 0)} title='Very Poor' className={'angry ' + checkIsActive(0)} style={{cursor: 'pointer'}}>Very Poor</button></li>
                        <li className='ic-widget-small__form-list__row__mood__item-m'><button onClick={(e) => handleChange(e, 1)} title='Poor' className={'frown ' + checkIsActive(1)} style={{ cursor: 'pointer' }}>Poor</button></li>
                        <li className='ic-widget-small__form-list__row__mood__item-m'><button onClick={(e) => handleChange(e, 2)} title='OK' className={'meh ' + checkIsActive(2)} style={{ cursor: 'pointer' }}>OK</button></li>
                        <li className='ic-widget-small__form-list__row__mood__item-m'><button onClick={(e) => handleChange(e, 3)} title='Good' className={'smile ' + checkIsActive(3)} style={{ cursor: 'pointer' }}>Good</button></li>
                        <li className='ic-widget-small__form-list__row__mood__item-m'><button onClick={(e) => handleChange(e, 4)} title='Very Good' className={'laugh ' + checkIsActive(4)} style={{ cursor: 'pointer' }}>Very Good</button></li>
                    </ul>
                </div>
                <div className="invalid-feedback">
                    {isInvalidError}
                </div>
            </Form.Group>
        </>
    );
}

export { FormGroupSelectMood }